<template>
  <div class="zjcf">
    <div class="content-left">
      <div style="text-align: left">
        <div class="left-title">走进城发</div>
        <div class="left-sub-title">ABOUT GANYU CHENGTOU</div>
        <div class="tab-menu">
          <div v-for="(item, i) in tabMenu" :key="i">
            <div
              v-if="item.level != 0"
              class="menu-item"
              :class="currentTab.num == i ? 'active-class' : ''"
              @click="changeMenu(item, i)"
            >
              <i class="el-icon-caret-right"></i>
              <span>{{ item.name }}</span>
            </div>
            <div v-if="item.chiidren">
              <div v-for="(item2, i2) in item.chiidren" :key="i2">
                <div class="sub-menu" @click="getChildMenu(item2, i, i2)">
                  <i class="el-icon-caret-right"></i>
                  <span>{{ item2.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-right">
      <div class="content-title">
        <span class="title-line">{{ currentTab.name }}</span>
      </div>
      <div class="content-info">
        <!-- 走进城发 -->
        <div v-show="currentTab.num == 0" class="go-chengfa">
          <div
            class="company-text  ql-editor "
            style="margin-bottom: 3vh"
            v-html="commonInfo.groupContent"
          ></div>
        </div>
        <!-- 集团简介 -->
        <div v-show="currentTab.num == 1" class="go-chengfa">
          <div class="company-text  ql-editor" style="margin: 2vh 0" v-html="commonInfo.groupContent">
          </div>
        </div>
        <!-- 组织架构 -->
        <div v-show="currentTab.num == 2">
          <img
            style="width: 100%"
            :src="BASE_URL + commonInfo.departmentPictures"
            alt=""
          />
        </div>
        <!-- 党建工作 -->
        <div v-show="currentTab.num == 3">
          <div>
            <div class="news-list">
              <div
                v-for="(item, i) in partyList"
                :key="i"
                class="news-item"
                @click="goToDetail(item.partyId)"
              >
                <i class="el-icon-caret-right"></i>
                <span style="margin-left: 3px">{{ item.partyTitle }}</span>
                <span style="margin-left: 3px; float: right;">{{ item.createTime }}</span>
              </div>
            </div>
            <!-- 分页 -->
            <el-pagination
            v-if="pages >0" 
              style="margin-top: 40px"
              background
              @current-change="handleCurrentChange"
              :page-size="15"
              layout="prev, pager, next"
              :page-count="pages"
            >
            </el-pagination>
          </div>
        </div>
        <!-- 企业文化 -->
        <div v-show="currentTab.num == 4" class="culture-type">
          <div class="culture-card">
            <img src="@/assets/page2/1.png" alt="" />
            <div class="culture-text">
              <div style="font-size: 16px; color: #fff">能攻坚</div>
            </div>
          </div>
          <div class="culture-card">
            <img src="@/assets/page2/2.png" alt="" />
            <div class="culture-text">
              <div style="font-size: 16px; color: #fff">善创业</div>
            </div>
          </div>
          <div class="culture-card">
            <img src="@/assets/page2/3.png" alt="" />
            <div class="culture-text">
              <div style="font-size: 16px; color: #fff">铸精品</div>
            </div>
          </div>
          <div class="culture-card">
            <img src="@/assets/page2/4.png" alt="" />
            <div class="culture-text">
              <div style="font-size: 16px; color: #fff">求卓越</div>
            </div>
          </div>
        </div>
        <!-- 荣誉墙 -->
        <div v-show="currentTab.num == 5">
          <div class="prime-wall" v-for="(prime, i) in primes" :key="i">
            <div class="prime year">
              <!-- <span style="color: #003f99; font-size: 26px; font-family: arial;"> -->
              {{ prime.primeYear }}
              <!-- </span> -->
            </div>
            <!-- <p
                style="
                  color: #666666;
                  font-size: 14px;
                  font-family: 微软雅黑;
                  max-width: px;
                  word-break: break-all;
                  line-height: 28px;
                "
              >
                官渎里立交工程获中国建筑工程鲁班奖。<br />
                苏州市创建国家园林城市先进单位。<br />
                苏州市文物保护先进集体。<br />
                官渎里立交工程获2002年度苏州市十大民心工程。
              </p> -->
            <div class="prime list">
              <p
                v-html="prime.primeLits"
                 class=" ql-editor"
                style="
                  color: #666666;
                  font-size: 14px;
                  max-width: px;
                  word-break: break-all;
                  font-family: 微软雅黑;
                  line-height: 28px;
                "
              ></p>
            </div>
          </div>
        </div>
        <!-- 领导分工 -->
        <div v-show="currentTab.num == 20">
          <div v-if="leaders">
            <div class=" ql-editor"
              style="font-size: 14px; font-family: 微软雅黑"
              v-html="leaders"
            ></div>
          </div>
        </div>
        <!-- 宣传片 -->
        <div v-show="currentTab.num == 40">
          <div v-if="commonInfo.videoUrl && currentTab.num == 40">
            <div  class="video-box" style="text-align: center;margin-top: 20px; ">
              <video controls autoplay="false"  style="width: 90%" :src="commonInfo.videoUrl"></video>
            </div>
            <p style="text-align: center;  font-size: 24px; font-weight: normal; font-style: normal; color: #000;">城投宣传片</p>
          </div>
        </div>
        <!-- 发展历程 -->
        <div v-show="currentTab.num == 6">
          <div class="developer-line" style="text-align: left">
            <el-timeline>
              <el-timeline-item
                :timestamp="item.timePoint"
                placement="top"
                v-for="(item, index) in timeLine"
                :key="index"
              >
                <el-card>
                  <div style="width: 33%">
                    <img
                      style="width: 100%; height: 100%"
                      :src="BASE_URL + item.topPicture"
                      alt=""
                    />
                  </div>
                  <div style="width: 65%">
                    <div
                      style="
                        font-size: 16px;
                        font-weight: bold;
                        color: #131313;
                        margin-bottom: 10px;
                        text-align: center;
                      "
                    >
                      {{ item.historyTitle }}
                    </div>
                    <div
                      style="
                        font-size: 14px;
                        color: #999999;
                        line-height: 2;
                        text-indent: 28px;
                      "
                    >
                      {{ item.historyContent }}
                    </div>
                  </div>
                </el-card>
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Timeline from "@/components/Timeline";
import {
  getHistoryInfo,
  getCommonInfo,
  getPartyAll,
  getAllPrimes,
  getAllLeaders,
} from "../../../js/api/index";
export default {
  components: { Timeline },
  data() {
    return {
      leaders: "",
      tabMenu: [
        { name: "走进城发", level: 0 },
        { name: "集团简介", level: 1 },
        {
          name: "组织架构",
          level: 1,
          chiidren: [{ name: "领导分工", level: 2 }],
        },
        { name: "党建工作", level: 1 },
        {
          name: "企业文化",
          level: 1,
          chiidren: [{ name: "宣传片", level: 2 }],
        },
        { name: "荣誉墙", level: 1 },
        { name: "发展历程", level: 1 },
      ],
      currentTab: {
        name: "走进城发",
        num: 0,
      },
      BASE_URL: BASE_URL,
      commonInfo: "",
      timeLine: [],
      partyList: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 15,
        type: 1,
      },
      pages: 1,
      primes: [],
    };
  },
  watch: {
    '$route'(to, from) {
      // 在路由发生变化时触发的回调函数
      let type = to.query.type;
      if (type) {
        if(type == 40){
          this.currentTab.name = '宣传片';
        }else if(type == 20){
          this.currentTab.name = '领导分工';
        }else{
          this.currentTab.name = this.tabMenu[type].name;
        }
        this.currentTab.num = type;
        if (type == 3) {
          this.pageInfo.pageNum = 1;
          this.pageInfo.type = 1;
          this.initParty();
        }
        if (type == 5) {
          this.getPrimes();
        }
        if (type == 20) {
          // alert("!!")
          this.getLeaders();
        }
      } else {
        this.currentTab = {
          name: "走进城发",
          num: 0,
        };
      }
    },
  },
  computed: {},
  methods: {
    checkRouter() {
      let type = this.$route.query.type; // 获取当前路由的查询参数对象
      if (type) {
        if(type == 40){
          this.currentTab.name = '宣传片';
        }else if(type == 20){
          this.currentTab.name = '领导分工';
        }else{
          this.currentTab.name = this.tabMenu[type].name;
        }
        this.currentTab.num = type;
        console.log(this.currentTab)
        if (type == 3) {
          this.pageInfo.pageNum = 1;
          this.pageInfo.type = 1;
          this.initParty();
        }
        if (type == 5) {
          this.getPrimes();
        }
        if (type == 20) {
          this.getLeaders();
        }
      } else {
        this.currentTab = {
          name: "走进城发",
          num: 0,
        };
      }
    },
    changeMenu(item, i) {
      this.currentTab.name = item.name;
      this.currentTab.num = i;
      let type = this.$route.query.type;
      if (type == i) return;
      this.$router.replace({ query: { type: i } }); // 只能用 name
    },

    getChildMenu(item, parentIndex, i) {
      console.log("item", item);
      let now = parentIndex + "" + i;
      this.currentTab.name = item.name;
      this.currentTab.num = i;
      let type = this.$route.query.type;
      if (type == now) return;
      console.log("now:", now);
      this.$router.replace({ query: { type: now} }); // 只能用 name
    },
    //组织结构图
    initOrigin() {
      getCommonInfo().then((res) => {
        console.log("res:", res);
        let list = res.data.list;
        this.commonInfo = list[0];
      });
    },
    goToDetail(id) {
      this.$router.push({ path: "/partydetail", query: { id: id } }); // 跳转并传递参数
    },
    initParty() {
      getPartyAll(this.pageInfo).then((res) => {
        let resData = res.data;
        let list = resData.list;
        // for (let index = 0; index < list.length; index++) {
        //   const element = list[index];
        //   element.timeType = this.splitTime(element.createTime);
        // }
        this.partyList = list;
        this.pages = resData.pages;
      });
    },
    getPrimes() {
      getAllPrimes().then((res) => {
        // let resData = res.data;
        console.log(res);
        if (res.data && res.data.length > 0) {
          this.primes = res.data;
        }
      });
    },
    getLeaders() {
      getAllLeaders().then((res) => {
        // let resData = res.data;
        console.log(res);
        this.leaders = res.data.leaders;
      });
    },
    async handleCurrentChange(val) {
      this.pageInfo.pageNum = val;
      await this.updatePartyData();
    },
    //分页更新新闻信息
    async updatePartyData() {
      getPartyAll(this.pageInfo).then((res) => {
        let resData = res.data;
        let list = resData.list;
        for (let index = 0; index < list.length; index++) {
          const element = list[index];
          element.timeType = this.splitTime(element.createTime);
        }
        this.partyList = list;
      });
    },
  },
  created() {
    this.checkRouter();
  },
  mounted() {
    this.initOrigin();
    getHistoryInfo().then((res) => {
      this.timeLine = res.data.list;
    });
  },
};
</script>

<style lang="scss" scoped>
.zjcf {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
}
.content-left {
  width: 28.6%;
  padding-left: 30px;
  padding-right: 0;
  padding-top: 40px;
  padding-bottom: 30vh;
  background: #f7f7f7;
  .left-title {
    font-family: 微软雅黑;
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    color: #003f99;
    padding: 0 0 5px 0;
    display: block;
    padding: 0 0 5px 0;
  }
  .left-sub-title {
    font-family: arial;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    color: #bababa;
    display: block;
  }
  .tab-menu {
    width: 75%;
    margin-top: 10px;
    .sub-menu {
      font-size: 14px;
      color: #666666;
      cursor: pointer;
      padding: 15px 10px;
      padding-left: 30px;
      border-bottom: 1px solid gainsboro;
    }
  }
  .menu-item {
    cursor: pointer;
    padding: 15px 10px;
    font-size: 16px;
    color: #666666;
  }
  .menu-item {
    border-bottom: 1px solid gainsboro;
  }
  .menu-item:hover,
  .sub-menu:hover {
    color: #003f99;
  }
  .active-class {
    color: #003f99;
  }
}
.content-right {
  width: 71.4%;
  padding-left: 30px;
  padding-right: 0;
  padding-top: 40px;
  .content-title {
    text-align: left;
    border-bottom: 1px solid gainsboro;
    padding: 5px 0 5px 15px;
    color: #003f99;
    position: relative;
  }
  .title-line::before {
    position: absolute;
    content: "";
    height: 20px;
    width: 4px;
    background: #003f99;
    left: 0;
  }
}
.go-chengfa {
  margin-top: 20px;
  .company-text {
    font-size: 14px;
    color: rgb(102, 102, 102);
    line-height: 24px;
    text-align: left;
    text-indent: 28px;
  }
}
.culture-type {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  .culture-card {
    position: relative;
    width: 49%;
    > img {
      width: 100%;
    }
    .culture-text {
      position: absolute;
      top: 30%;
      left: 18%;
    }
  }
}
.developer-line {
  margin-top: 20px;
}
.news-list {
  .news-item {
    color: #393939;
    font-size: 15px;
    text-align: left;
    cursor: pointer;
    margin: 20px 0;
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .news-item:hover {
    color: #003f99;
  }
}
.prime-wall {
  margin-top: 40px;
  margin-left: 50px;
  height: 220px;
  width: 700px;
  background-color: #f7f7f7;
  vertical-align: middle;
  -webkit-transition: background-color 0.5s ease, margin-left 0.5s ease;
  -moz-transition: background-color 0.5s ease, margin-left 0.5s ease;
  -o-transition: background-color 0.5s ease, margin-left 0.5s ease;
  transition: background-color 0.5s ease, margin-left 0.5s ease;
  display: flex;
  align-items: center;
}
.prime-wall:hover {
  background-color: white;
  margin-left: 0px;
}
.prime {
  // display: inline-block;
  position: relative;
}
.year {
  color: #003f99;
  font-size: 26px;
  font-family: arial;
  width: 130px;
  text-align: center;
}

.list {
  width: 370px;
  text-align: left;
  position: relative;
  // top: 50%;
  // transform: translateY(-50%);
}
</style>